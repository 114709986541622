import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import React, { Fragment, useState, useEffect } from "react";

import Spinner from "../layout/Spinner";
import { fetchPrice, updatePrice } from "../../actions/price";

const Price = ({
  price: { price, loading },
  fetchPrice,
  updatePrice,
  history,
}) => {
  const [formData, setFormData] = useState({
    program: {
      plan: { basic: 0, standard: 0, premium: 0 },
    },
    additional: {
      dj: { basic: 0, standard: 0, premium: 0 },
      flower: { basic: 0, standard: 0, premium: 0 },
      balloons: { basic: 0, standard: 0, premium: 0 },
      centerstage: { basic: 0, standard: 0, premium: 0 },
      crockery: { basic: 0, standard: 0, premium: 0 },
      generator: { basic: 0, standard: 0, premium: 0 },
    },
    misc: {
      tandoor: 0,
      coffeemachine: 0,
      vipwaiter: 0,
      normalwaiter: 0,
      guard: 0,
      sweeper: 0,
    },
  });

  useEffect(() => {
    fetchPrice(null);
    if (price) {
      setFormData({
        program:
          loading || !price.program
            ? { basic: "", standard: "", premium: "" }
            : price.program,
        additional:
          loading || !price.additional
            ? { basic: "", standard: "", premium: "" }
            : price.additional,
        misc:
          loading || !price.misc
            ? { basic: "", standard: "", premium: "" }
            : price.misc,
      });
    }
  }, [fetchPrice, loading]);

  const {
    program: { plan },
    additional: { dj, flower, balloons, centerstage, crockery, generator },
    misc: { tandoor, coffeemachine, vipwaiter, normalwaiter, guard, sweeper },
  } = formData;

  const onChange = (e, obj, subobj) => {
    if (subobj) {
      setFormData({
        ...formData,
        [obj]: {
          ...formData[obj],
          [subobj]: {
            ...formData[obj][subobj],
            [e.target.name]: e.target.value,
          },
        },
      });
    } else {
      setFormData({
        ...formData,
        [obj]: {
          ...formData[obj],
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    updatePrice(formData, history);
  };

  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <section className="container">
        <h1 className="large text-primary">Update Price Form</h1>
        <form className="form" onSubmit={(e) => onSubmit(e)}>
          <div className="lead text-primary">
            <h1>Package Details</h1>
          </div>

          <div className="form-group">
            <label className="label-heading text-primary"> Plan </label>
            <div className="form-group">
              <label className="label-item text-dark"> Basic </label>
              <input
                type="number"
                name="basic"
                value={plan.basic}
                onChange={(e) => onChange(e, "program", "plan")}
                min={0}
              />
            </div>
            <div className="form-group">
              <label className="label-item text-dark"> Standard </label>
              <input
                type="number"
                name="standard"
                value={plan.standard}
                onChange={(e) => onChange(e, "program", "plan")}
                min={0}
              />
            </div>
            <div className="form-group">
              <label className="label-item text-dark"> Premium </label>
              <input
                type="number"
                name="premium"
                value={plan.premium}
                onChange={(e) => onChange(e, "program", "plan")}
                min={0}
              />
            </div>
          </div>
          <div className="lead text-primary">
            <h1>Additional Package Details</h1>
          </div>
          <div className="form-group">
            <label className="label-heading text-primary"> DJ </label>
            <div className="form-group">
              <label className="label-item text-dark"> Basic </label>
              <input
                type="number"
                name="basic"
                value={dj.basic}
                onChange={(e) => onChange(e, "additional", "dj")}
                min={0}
              />
            </div>
            <div className="form-group">
              <label className="label-item text-dark"> Standard </label>
              <input
                type="number"
                name="standard"
                value={dj.standard}
                onChange={(e) => onChange(e, "additional", "dj")}
                min={0}
              />
            </div>
            <div className="form-group">
              <label className="label-item text-dark"> Premium </label>
              <input
                type="number"
                name="premium"
                value={dj.premium}
                onChange={(e) => onChange(e, "additional", "dj")}
                min={0}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="label-heading text-primary">
              {" "}
              Flower Decoration{" "}
            </label>
            <div className="form-group">
              <label className="label-item text-dark"> Basic </label>
              <input
                type="number"
                name="basic"
                value={flower.basic}
                onChange={(e) => onChange(e, "additional", "flower")}
                min={0}
              />
            </div>
            <div className="form-group">
              <label className="label-item text-dark"> Standard </label>
              <input
                type="number"
                name="standard"
                value={flower.standard}
                onChange={(e) => onChange(e, "additional", "flower")}
                min={0}
              />
            </div>
            <div className="form-group">
              <label className="label-item text-dark"> Premium </label>
              <input
                type="number"
                name="premium"
                value={flower.premium}
                onChange={(e) => onChange(e, "additional", "flower")}
                min={0}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="label-heading text-primary">
              {" "}
              Ballons Decoration{" "}
            </label>
            <div className="form-group">
              <label className="label-item text-dark"> Basic </label>
              <input
                type="number"
                name="basic"
                value={balloons.basic}
                onChange={(e) => onChange(e, "additional", "balloons")}
                min={0}
              />
            </div>
            <div className="form-group">
              <label className="label-item text-dark"> Standard </label>
              <input
                type="number"
                name="standard"
                value={balloons.standard}
                onChange={(e) => onChange(e, "additional", "balloons")}
                min={0}
              />
            </div>
            <div className="form-group">
              <label className="label-item text-dark"> Premium </label>
              <input
                type="number"
                name="premium"
                value={balloons.premium}
                onChange={(e) => onChange(e, "additional", "balloons")}
                min={0}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="label-heading text-primary"> Center Stage </label>
            <div className="form-group">
              <label className="label-item text-dark"> Basic </label>
              <input
                type="number"
                name="basic"
                value={centerstage.basic}
                onChange={(e) => onChange(e, "additional", "centerstage")}
                min={0}
              />
            </div>
            <div className="form-group">
              <label className="label-item text-dark"> Standard </label>
              <input
                type="number"
                name="standard"
                value={centerstage.standard}
                onChange={(e) => onChange(e, "additional", "centerstage")}
                min={0}
              />
            </div>
            <div className="form-group">
              <label className="label-item text-dark"> Premium </label>
              <input
                type="number"
                name="premium"
                value={centerstage.premium}
                onChange={(e) => onChange(e, "additional", "centerstage")}
                min={0}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="label-heading text-primary">
              {" "}
              Crockery (per plate){" "}
            </label>
            <div className="form-group">
              <label className="label-item text-dark"> Basic </label>
              <input
                type="number"
                name="basic"
                value={crockery.basic}
                onChange={(e) => onChange(e, "additional", "crockery")}
                min={0}
              />
            </div>
            <div className="form-group">
              <label className="label-item text-dark"> Standard </label>
              <input
                type="number"
                name="standard"
                value={crockery.standard}
                onChange={(e) => onChange(e, "additional", "crockery")}
                min={0}
              />
            </div>
            <div className="form-group">
              <label className="label-item text-dark"> Premium </label>
              <input
                type="number"
                name="premium"
                value={crockery.premium}
                onChange={(e) => onChange(e, "additional", "crockery")}
                min={0}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="label-heading text-primary"> Generator </label>
            <div className="form-group">
              <label className="label-item text-dark"> Basic </label>
              <input
                type="number"
                name="basic"
                value={generator.basic}
                onChange={(e) => onChange(e, "additional", "generator")}
                min={0}
              />
            </div>
            <div className="form-group">
              <label className="label-item text-dark"> Standard </label>
              <input
                type="number"
                name="standard"
                value={generator.standard}
                onChange={(e) => onChange(e, "additional", "generator")}
                min={0}
              />
            </div>
            <div className="form-group">
              <label className="label-item text-dark"> Premium </label>
              <input
                type="number"
                name="premium"
                value={generator.premium}
                onChange={(e) => onChange(e, "additional", "generator")}
                min={0}
              />
            </div>
          </div>
          <div className="lead text-primary">
            <h1>Miscellaneous Package</h1>
          </div>
          <div className="form-group">
            <label className="label-item text-primary"> Tandoor </label>
            <input
              type="number"
              name="tandoor"
              value={tandoor}
              onChange={(e) => onChange(e, "misc")}
              min={0}
            />
          </div>
          <div className="form-group">
            <label className="label-item text-primary"> Coffee Machine </label>
            <input
              type="number"
              name="coffeemachine"
              value={coffeemachine}
              onChange={(e) => onChange(e, "misc")}
              min={0}
            />
          </div>
          <div className="form-group">
            <label className="label-item text-primary"> VIP Waiter </label>
            <input
              type="number"
              name="vipwaiter"
              value={vipwaiter}
              onChange={(e) => onChange(e, "misc")}
              min={0}
            />
          </div>
          <div className="form-group">
            <label className="label-item text-primary"> Normal Waiter </label>
            <input
              type="number"
              name="normalwaiter"
              value={normalwaiter}
              onChange={(e) => onChange(e, "misc")}
              min={0}
            />
          </div>
          <div className="form-group">
            <label className="label-item text-primary"> Guard </label>
            <input
              type="number"
              name="guard"
              value={guard}
              onChange={(e) => onChange(e, "misc")}
              min={0}
            />
          </div>
          <div className="form-group">
            <label className="label-item text-primary"> Sweeper </label>
            <input
              type="number"
              name="sweeper"
              value={sweeper}
              onChange={(e) => onChange(e, "misc")}
              min={0}
            />
          </div>
          <input type="submit" className="btn btn-primary my-1" />
          <Link className="btn btn-light my-1" to="/dashboard">
            Go Back
          </Link>
        </form>
      </section>
    </Fragment>
  );
};

Price.propTypes = {
  price: PropTypes.object.isRequired,
  fetchPrice: PropTypes.func.isRequired,
  updatePrice: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  price: state.price,
});

export default connect(mapStateToProps, {
  fetchPrice,
  updatePrice,
})(withRouter(Price));
