import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import React, { Fragment, useState, useEffect } from "react";

import Spinner from "../layout/Spinner";
import { fetchPrice } from "../../actions/price";
import { updateBooking, fetchLatestID } from "../../actions/booking";

const Booking = ({
  booking: { booking, latestID, loading },
  price: { price },
  fetchPrice,
  updateBooking,
  fetchLatestID,
  history,
}) => {
  const curr = new Date();
  curr.setDate(curr.getDate());
  let currDate = curr.toISOString().split("T")[0];

  const [formData, setFormData] = useState({
    estimate: true,
    estimateid: "Fetching...",
    bookingid: "",
    customer: {
      name: "",
      phoneno: "",
      address: "",
    },
    program: {
      totalpersons: 0,
      programdate: "",
      bookingdate: currDate,
      plan: "",
    },
    additional: {
      dj: "",
      flower: "",
      balloons: "",
      centerstage: "",
      crockery: "",
      generator: "",
    },
    misc: {
      tandoor: 0,
      coffeemachine: 0,
      vipwaiter: 0,
      normalwaiter: 0,
      guard: 0,
      sweeper: true,
    },
    total: {
      paid: 0,
      amount: 0,
      balance: 0,
      discount: 0,
    },
  });

  useEffect(() => {
    fetchLatestID();
    if (booking) {
      fetchPrice(booking.price);
      setFormData((formData) => {
        return {
          estimate: loading || !booking.estimate ? true : booking.estimate,
          estimateid: loading || !booking.estimateid ? "" : booking.estimateid,
          bookingid: loading || !booking.bookingid ? "" : booking.bookingid,
          customer: loading || !booking.customer ? "" : booking.customer,
          program: loading || !booking.program ? "" : booking.program,
          additional: loading || !booking.additional ? "" : booking.additional,
          misc: loading || !booking.misc ? "" : booking.misc,
          total: loading || !booking.total ? "" : booking.total,
        };
      });
    } else {
      fetchPrice(null);
    }
  }, [fetchLatestID, fetchPrice, booking, loading]);

  const {
    estimate,
    estimateid,
    bookingid,
    customer: { name, address, phoneno },
    program: { totalpersons, programdate, bookingdate, plan },
    additional: { dj, flower, balloons, centerstage, crockery, generator },
    misc: { tandoor, coffeemachine, vipwaiter, normalwaiter, guard, sweeper },
    total: { paid, amount, balance, discount },
  } = formData;

  const calculateTotal = () => {
    let amt = 0;
    if (plan) amt += price.program.plan[plan];
    if (dj) amt += price.additional.dj[dj];
    if (flower) amt += price.additional.flower[flower];
    if (balloons) amt += price.additional.balloons[balloons];
    if (centerstage) amt += price.additional.centerstage[centerstage];
    if (crockery) amt += price.additional.crockery[crockery] * totalpersons;
    if (generator) amt += price.additional.generator[generator];
    if (tandoor) amt += price.misc.tandoor * tandoor;
    if (coffeemachine) amt += price.misc.coffeemachine * coffeemachine;
    if (vipwaiter) amt += price.misc.vipwaiter * vipwaiter;
    if (normalwaiter) amt += price.misc.normalwaiter * normalwaiter;
    if (guard) amt += price.misc.guard * guard;
    if (sweeper) amt += price.misc.sweeper * sweeper;

    setFormData((formData) => {
      return {
        ...formData,
        ["total"]: {
          ...formData["total"],
          balance: Number(amt) - Number(discount) - Number(paid),
          amount: amt,
        },
      };
    });
  };

  const onChange = (e, obj, subobj) => {
    setFormData({
      ...formData,
      [obj]: {
        ...formData[obj],
        [e.target.name]: subobj ? subobj : e.target.value,
      },
      estimateid: booking ? booking.estimateid : latestID.estimateid,
    });
    calculateTotal();
  };

  const onSubmit = (e) => {
    e.preventDefault();
    // calculateTotal();
    booking
      ? updateBooking(formData, history, true)
      : updateBooking(formData, history, false);
  };

  return loading && booking === null ? (
    <Spinner />
  ) : (
    <Fragment>
      <section className="container">
        <h1 className="large text-primary">Booking Form</h1>
        <small>* = required field</small>
        <form className="form" onSubmit={(e) => onSubmit(e)}>
          <div className="form-group">
            <label className="label-heading text-primary">
              <input
                type="checkbox"
                name="estimate"
                checked={estimate}
                value={estimate}
                onChange={(e) =>
                  setFormData((formData) => {
                    return {
                      ...formData,
                      estimate: booking
                        ? booking.bookingid
                          ? estimate
                          : !estimate
                        : !estimate,
                      estimateid: booking
                        ? booking.estimateid
                        : latestID.estimateid,
                      bookingid: estimate
                        ? booking
                          ? booking.bookingid
                            ? booking.bookingid
                            : latestID.bookingid
                          : latestID.bookingid
                        : "",
                    };
                  })
                }
              />{" "}
              Estimate
            </label>
          </div>
          <div className="form-group">
            <label className="label-heading text-primary">
              {" "}
              Estimate ID: {estimateid}{" "}
            </label>
            <label className="label-heading text-primary">
              {" "}
              Booking ID: {bookingid > 0 ? bookingid : "ESTIMATE ONLY"}{" "}
            </label>
          </div>
          <div className="lead text-primary">
            <h1>Customer Details</h1>
          </div>
          <div className="form-group">
            <label className="label-heading text-primary"> Name </label>
            <input
              type="text"
              name="name"
              value={name}
              onChange={(e) => onChange(e, "customer")}
              required
            />
          </div>
          <div className="form-group">
            <label className="label-heading text-primary"> Address </label>
            <input
              type="text"
              name="address"
              value={address}
              onChange={(e) => onChange(e, "customer")}
              required
            />
          </div>
          <div className="form-group">
            <label className="label-heading text-primary">Phone Nos </label>
            <input
              type="text"
              name="phoneno"
              value={phoneno}
              onChange={(e) => onChange(e, "customer")}
              required
            />
            <small className="form-text">
              Please use comma for multiple nos. (eg. 1234567890,9876543210)
            </small>
          </div>
          <div className="lead text-primary">
            <h1>Package Details</h1>
          </div>
          <div className="form-group">
            <label className="label-heading text-primary">
              {" "}
              Total Persons{" "}
            </label>
            <input
              type="number"
              name="totalpersons"
              value={totalpersons}
              onChange={(e) => onChange(e, "program")}
              min={0}
              required
            />
          </div>
          <div className="form-group">
            <label className="label-heading text-primary">Program Date </label>
            <input
              type="date"
              name="programdate"
              value={programdate}
              onChange={(e) => onChange(e, "program")}
              required
            />
          </div>
          <div className="form-group">
            <label className="label-heading text-primary">Booking Date </label>
            <input
              type="date"
              name="bookingdate"
              value={bookingdate}
              onChange={(e) => onChange(e, "program")}
              required
            />
          </div>
          <div className="form-group">
            <label className="label-heading text-primary"> Plan </label>
            <div className="radio-group">
              <label className="label-item">
                <label className="label-price text-dark">
                  <input
                    type="radio"
                    name="plan"
                    value={plan}
                    checked={plan === "basic"}
                    onChange={(e) => onChange(e, "program", "basic")}
                    required
                  />{" "}
                  Basic{" "}
                </label>
                ₹
                {price && Number(price.program.plan.basic).toLocaleString("hi")}
                /-
              </label>
              <label className="label-item">
                <label className="label-price text-dark">
                  <input
                    type="radio"
                    name="plan"
                    value={plan}
                    checked={plan === "standard"}
                    onChange={(e) => onChange(e, "program", "standard")}
                    required
                  />{" "}
                  Standard{" "}
                </label>
                ₹
                {price &&
                  Number(price.program.plan.standard).toLocaleString("hi")}
                /-
              </label>
              <label className="label-item">
                <label className="label-price text-dark">
                  <input
                    type="radio"
                    name="plan"
                    value={plan}
                    checked={plan === "premium"}
                    onChange={(e) => onChange(e, "program", "premium")}
                    required
                  />{" "}
                  Premium{" "}
                </label>
                ₹
                {price &&
                  Number(price.program.plan.premium).toLocaleString("hi")}
                /-
              </label>
            </div>
          </div>
          <br></br>
          <div className="lead text-primary">
            <h1>Additional Package Details</h1>
          </div>
          <div className="form-group">
            <label className="label-heading text-primary"> DJ </label>
            <div className="radio-group">
              <label className="label-item">
                <label className="label-price text-dark">
                  <input
                    type="radio"
                    name="dj"
                    value={dj}
                    checked={dj === "basic"}
                    onChange={(e) => onChange(e, "additional", "basic")}
                  />{" "}
                  Basic{" "}
                </label>
                ₹
                {price &&
                  Number(price.additional.dj.basic).toLocaleString("hi")}
                /-
              </label>
              <label className="label-item">
                <label className="label-price text-dark">
                  {" "}
                  <input
                    type="radio"
                    name="dj"
                    value={dj}
                    checked={dj === "standard"}
                    onChange={(e) => onChange(e, "additional", "standard")}
                  />{" "}
                  Standard (With Permission){" "}
                </label>
                ₹
                {price &&
                  Number(price.additional.dj.standard).toLocaleString("hi")}
                /-
              </label>
            </div>
          </div>
          <div className="form-group">
            <label className="label-heading text-primary">
              {" "}
              Flower Decoration{" "}
            </label>
            <div className="radio-group">
              <label className="label-item">
                <label className="label-price text-dark">
                  <input
                    type="radio"
                    name="flower"
                    value={flower}
                    checked={flower === "basic"}
                    onChange={(e) => onChange(e, "additional", "basic")}
                  />{" "}
                  Basic{" "}
                </label>
                ₹
                {price &&
                  Number(price.additional.flower.basic).toLocaleString("hi")}
                /-
              </label>
              <label className="label-item">
                <label className="label-price text-dark">
                  <input
                    type="radio"
                    name="flower"
                    value={flower}
                    checked={flower === "standard"}
                    onChange={(e) => onChange(e, "additional", "standard")}
                  />{" "}
                  Standard{" "}
                </label>
                ₹
                {price &&
                  Number(price.additional.flower.standard).toLocaleString("hi")}
                /-
              </label>
              <label className="label-item">
                <label className="label-price text-dark">
                  <input
                    type="radio"
                    name="flower"
                    value={flower}
                    checked={flower === "premium"}
                    onChange={(e) => onChange(e, "additional", "premium")}
                  />{" "}
                  Premium{" "}
                </label>
                ₹
                {price &&
                  Number(price.additional.flower.premium).toLocaleString("hi")}
                /-
              </label>
            </div>
          </div>
          <div className="form-group">
            <label className="label-heading text-primary">
              {" "}
              Balloons Decoration{" "}
            </label>
            <div className="radio-group">
              <label className="label-item">
                <label className="label-price text-dark">
                  <input
                    type="radio"
                    name="balloons"
                    value={balloons}
                    checked={balloons === "basic"}
                    onChange={(e) => onChange(e, "additional", "basic")}
                  />{" "}
                  Basic{" "}
                </label>
                ₹
                {price &&
                  Number(price.additional.balloons.basic).toLocaleString("hi")}
                /-
              </label>
              <label className="label-item">
                <label className="label-price text-dark">
                  <input
                    type="radio"
                    name="balloons"
                    value={balloons}
                    checked={balloons === "standard"}
                    onChange={(e) => onChange(e, "additional", "standard")}
                  />{" "}
                  Standard{" "}
                </label>
                ₹
                {price &&
                  Number(price.additional.balloons.standard).toLocaleString(
                    "hi"
                  )}
                /-
              </label>
            </div>
          </div>
          <div className="form-group">
            <label className="label-heading text-primary"> Center Stage </label>
            <div className="radio-group">
              <label className="label-item">
                <label className="label-price text-dark">
                  <input
                    type="radio"
                    name="centerstage"
                    value={centerstage}
                    checked={centerstage === "basic"}
                    onChange={(e) => onChange(e, "additional", "basic")}
                  />{" "}
                  Basic{" "}
                </label>
                ₹
                {price &&
                  Number(price.additional.centerstage.basic).toLocaleString(
                    "hi"
                  )}
                /-
              </label>
              <label className="label-item">
                <label className="label-price text-dark">
                  <input
                    type="radio"
                    name="centerstage"
                    value={centerstage}
                    checked={centerstage === "standard"}
                    onChange={(e) => onChange(e, "additional", "standard")}
                  />{" "}
                  Standard{" "}
                </label>
                ₹
                {price &&
                  Number(price.additional.centerstage.standard).toLocaleString(
                    "hi"
                  )}
                /-
              </label>
            </div>
          </div>
          <div className="form-group">
            <label className="label-heading text-primary">
              {" "}
              Crockery (per plate){" "}
            </label>
            <div className="radio-group">
              <label className="label-item">
                <label className="label-price text-dark">
                  {" "}
                  <input
                    type="radio"
                    name="crockery"
                    value={crockery}
                    checked={crockery === "basic"}
                    onChange={(e) => onChange(e, "additional", "basic")}
                  />{" "}
                  Basic{" "}
                </label>
                ₹
                {price &&
                  Number(price.additional.crockery.basic).toLocaleString("hi")}
                /-
              </label>
              <label className="label-item">
                <label className="label-price text-dark">
                  {" "}
                  <input
                    type="radio"
                    name="crockery"
                    value={crockery}
                    checked={crockery === "standard"}
                    onChange={(e) => onChange(e, "additional", "standard")}
                  />{" "}
                  Standard{" "}
                </label>
                ₹
                {price &&
                  Number(price.additional.crockery.standard).toLocaleString(
                    "hi"
                  )}
                /-
              </label>
              <label className="label-item">
                <label className="label-price text-dark">
                  {" "}
                  <input
                    type="radio"
                    name="crockery"
                    value={crockery}
                    checked={crockery === "premium"}
                    onChange={(e) => onChange(e, "additional", "premium")}
                  />{" "}
                  Premium{" "}
                </label>
                ₹
                {price &&
                  Number(price.additional.crockery.premium).toLocaleString(
                    "hi"
                  )}
                /-
              </label>
            </div>
          </div>
          <div className="form-group">
            <label className="label-heading text-primary"> Generator </label>
            <div className="radio-group">
              <label className="label-item">
                <label className="label-price text-dark">
                  <input
                    type="radio"
                    name="generator"
                    value={generator}
                    checked={generator === "basic"}
                    onChange={(e) => onChange(e, "additional", "basic")}
                  />{" "}
                  Basic (Lighting){" "}
                </label>
                ₹
                {price &&
                  Number(price.additional.generator.basic).toLocaleString("hi")}
                /-
              </label>

              <label className="label-item">
                <label className="label-price text-dark">
                  <input
                    type="radio"
                    name="generator"
                    value={generator}
                    checked={generator === "standard"}
                    onChange={(e) => onChange(e, "additional", "standard")}
                  />{" "}
                  Standard (Lighting + Cooler/Fan){" "}
                </label>
                ₹
                {price &&
                  Number(price.additional.generator.standard).toLocaleString(
                    "hi"
                  )}
                /-
              </label>
            </div>
          </div>
          <div className="lead text-primary">
            <h1>Miscellaneous Package</h1>
          </div>
          <div className="form-group">
            <label className="label-heading text-primary"> Tandoor </label>
            <input
              type="number"
              name="tandoor"
              value={tandoor}
              onChange={(e) => onChange(e, "misc")}
              min={0}
            />
            <label className="label-price text-dark">
              {" "}
              ₹{price && Number(price.misc.tandoor).toLocaleString("hi")}/-
            </label>
          </div>
          <div className="form-group">
            <label className="label-heading text-primary">
              {" "}
              Coffee Machine{" "}
            </label>
            <input
              type="number"
              name="coffeemachine"
              value={coffeemachine}
              onChange={(e) => onChange(e, "misc")}
              min={0}
            />
            <label className="label-price text-dark">
              {" "}
              ₹{price && Number(price.misc.coffeemachine).toLocaleString("hi")}
              /-
            </label>
          </div>
          <div className="form-group">
            <label className="label-heading text-primary"> VIP Waiter </label>
            <input
              type="number"
              name="vipwaiter"
              value={vipwaiter}
              onChange={(e) => onChange(e, "misc")}
              min={0}
            />
            <label className="label-price text-dark">
              {" "}
              ₹{price && Number(price.misc.vipwaiter).toLocaleString("hi")}/-
            </label>
          </div>
          <div className="form-group">
            <label className="label-heading text-primary">
              {" "}
              Normal Waiter{" "}
            </label>
            <input
              type="number"
              name="normalwaiter"
              value={normalwaiter}
              onChange={(e) => onChange(e, "misc")}
              min={0}
            />
            <label className="label-price text-dark">
              {" "}
              ₹{price && Number(price.misc.normalwaiter).toLocaleString("hi")}/-
            </label>
          </div>
          <div className="form-group">
            <label className="label-heading text-primary"> Guard </label>
            <input
              type="number"
              name="guard"
              value={guard}
              onChange={(e) => onChange(e, "misc")}
              min={0}
            />
            <label className="label-price text-dark">
              {" "}
              ₹{price && Number(price.misc.guard).toLocaleString("hi")}/-
            </label>
          </div>
          <div className="form-group">
            <label className="label-heading text-primary">
              <input
                type="checkbox"
                name="sweeper"
                checked={sweeper}
                value={sweeper}
                onChange={() => {
                  setFormData((formData) => {
                    return {
                      ...formData,
                      misc: { ...formData["misc"], sweeper: !sweeper },
                    };
                  });
                  // calculateTotal();
                }}
                min={0}
              />{" "}
              Sweeper{" "}
              <label className="label-price text-dark">
                ₹{price && Number(price.misc.sweeper).toLocaleString("hi")}/-
              </label>
            </label>
          </div>
          <br></br> <br></br>
          <div className="form-group">
            <label className="label-heading text-primary">
              Total Amount: ₹{Number(amount).toLocaleString("hi")}/-
            </label>
          </div>
          <div className="form-group">
            <label className="label-heading text-primary">
              Total Paid: ₹{Number(paid).toLocaleString("hi")}/-
            </label>
          </div>
          <div className="form-group">
            <label className="label-heading text-primary">
              Discount:{" "}
              <input
                type="number"
                name="discount"
                value={discount}
                onChange={(e) => {
                  onChange(e, "total");
                }}
                min={0}
              />
            </label>
          </div>
          <div className="form-group">
            <label className="label-heading text-primary">
              Total Balance: ₹{Number(balance).toLocaleString("hi")}/-{" "}
            </label>
            <Link
              to="#"
              className="btn btn-dark my"
              onClick={() => calculateTotal()}
            >
              {" "}
              Refresh{" "}
            </Link>
            <div>
              <small>Please click Refresh before submitting.</small>
            </div>
          </div>
          <input type="submit" className="btn btn-primary my-1" />
          <Link className="btn btn-light my-1" to="/dashboard">
            Go Back
          </Link>
        </form>
      </section>
    </Fragment>
  );
};

Booking.propTypes = {
  price: PropTypes.object.isRequired,
  booking: PropTypes.object.isRequired,
  fetchPrice: PropTypes.func.isRequired,
  updateBooking: PropTypes.func.isRequired,
  fetchLatestID: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  price: state.price,
  booking: state.booking,
});

export default connect(mapStateToProps, {
  fetchPrice,
  fetchLatestID,
  updateBooking,
})(withRouter(Booking));
