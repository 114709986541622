import React from "react";
import PropTypes from "prop-types";

const Payment = (props) => {
  return (
    <section className="container">
      <h1>Under Construction, check back later</h1>
    </section>
  );
};

Payment.propTypes = {};

export default Payment;
