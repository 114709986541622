import axios from "axios";

import { setAlert } from "./alert";
import { BOOKING_API, BOOKINGS_API } from "../common/apiRoutes";
import {
  FETCH_ID,
  GET_BOOKING,
  BOOKINGS_LOADED,
  CLEAR_BOOKING,
  CLEAR_BOOKINGS,
} from "./types";

export const fetchLatestID = () => async (dispatch) => {
  try {
    const res = await axios.get(BOOKING_API);
    dispatch({ type: FETCH_ID, payload: res.data.latestID });
  } catch (err) {
    dispatch(setAlert(err.response.data.error, "danger"));
  }
};

export const updateBooking = (formData, history, edit = false) => async (
  dispatch
) => {
  try {
    const config = { headers: { "Content-Type": "application/json" } };
    await axios.post(BOOKING_API, formData, config);
    dispatch(setAlert(edit ? "Booking Updated" : "Booking Added", "success"));
    history.push("/dashboard");
  } catch (err) {
    const error = err.response.data.error;
    if (error) {
      error.forEach((e) => dispatch(setAlert(e.msg, "danger", 3000)));
    }
  }
};

export const fetchBookings = () => async (dispatch) => {
  try {
    const res = await axios.get(BOOKINGS_API);
    dispatch({ type: BOOKINGS_LOADED, payload: res.data.bookings });
  } catch (err) {
    const error = err.response.data.error;
    if (error) {
      error.forEach((e) => dispatch(setAlert(e.msg, "danger", 3000)));
    }
    dispatch({ type: CLEAR_BOOKINGS });
  }
};

export const fetchBooking = (estimateid, history) => async (dispatch) => {
  try {
    const res = await axios.get(`${BOOKING_API}/${estimateid}`);
    let booking = res.data.booking;
    booking.program.programdate = booking.program.programdate.split("T")[0];
    booking.program.bookingdate = booking.program.bookingdate.split("T")[0];
    dispatch({ type: GET_BOOKING, payload: booking });
    history.push("/booking");
  } catch (err) {
    const error = err.response.data.error;
    if (error) {
      error.forEach((e) => dispatch(setAlert(e.msg, "danger", 3000)));
    }
    dispatch({ type: CLEAR_BOOKING });
  }
};
