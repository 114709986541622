import axios from "axios";

import { setAlert } from "./alert";
import setAuthToken from "../common/setAuthToken";
import { CHECKTOKEN_API, REGISTER_API, LOGIN_API } from "../common/apiRoutes";
import {
  LOGOUT,
  AUTH_FAIL,
  USER_LOADED,
  AUTH_SUCCESS,
  CLEAR_BOOKINGS,
} from "./types";

export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    const res = await axios.get(CHECKTOKEN_API);
    dispatch({ type: USER_LOADED, payload: res.data.user });
  } catch (err) {
    dispatch({ type: AUTH_FAIL });
  }
};

export const register = ({ name, email, password, referral }) => async (
  dispatch
) => {
  const config = { headers: { "Content-Type": "application/json" } };
  const body = JSON.stringify({ name, email, password, referral });

  try {
    const res = await axios.post(REGISTER_API, body, config);
    dispatch(setAlert("Account Created", "success", 1000));
    dispatch({ type: AUTH_SUCCESS, payload: res.data.token });
    dispatch(loadUser());
  } catch (err) {
    const error = err.response.data.error;
    if (error) {
      error.forEach((e) => dispatch(setAlert(e.msg, "danger")));
    }
    dispatch({ type: AUTH_FAIL });
  }
};

export const login = ({ email, password }) => async (dispatch) => {
  const config = { headers: { "Content-Type": "application/json" } };
  const body = JSON.stringify({ email, password });

  try {
    const res = await axios.post(LOGIN_API, body, config);
    dispatch(setAlert("Login Success", "success", 1000));
    dispatch({ type: AUTH_SUCCESS, payload: res.data.token });
    dispatch(loadUser());
  } catch (err) {
    const error = err.response.data.error;
    if (error) {
      error.forEach((e) => dispatch(setAlert(e.msg, "danger")));
    }
    dispatch({ type: AUTH_FAIL });
  }
};

export const logout = () => (dispatch) => {
  dispatch({ type: CLEAR_BOOKINGS });
  dispatch({ type: LOGOUT });
};
