import {
  FETCH_ID,
  GET_BOOKING,
  CLEAR_BOOKING,
  CLEAR_BOOKINGS,
  BOOKINGS_LOADED,
} from "../actions/types";

const initialState = {
  booking: null,
  bookings: [],
  latestID: null,
  loading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ID:
      return { ...state, latestID: payload, loading: false };
    case GET_BOOKING:
      return { ...state, booking: payload, bookings: [], loading: false };
    case BOOKINGS_LOADED:
      return {
        ...state,
        booking: null,
        bookings: payload,
        latestID: null,
        loading: false,
      };
    case CLEAR_BOOKING:
      return { ...state, booking: null, latestID: null, loading: false };
    case CLEAR_BOOKINGS:
      return { ...state, bookings: [], latestID: null, loading: false };
    default:
      return { ...state };
  }
}
